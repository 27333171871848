























































































































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { LSS } from '@core/services';
import { KEYWORD_RECOMENDS } from '../../utils/apptweakqueries';
import { COUNTRIES } from '../../utils/constants';

export default Vue.extend({
  name: 'AddKeys',
  computed: {
    recomendHeaders: {
      get() {
        return [
          {
            text: this.$t('keyHeaders.keyword'),
            align: 'start',
            value: 'keyword',
          },
          { text: this.$t('keyHeaders.popularity'), value: 'volume' },
          {
            text: '',
            value: 'addKeywordPlus',
          },
      ]
      }
    },
    matchTypes: {
      get() {
        return [this.$t('addKeys.matchExact'), this.$t('addKeys.matchBroad')];
      }
    }
  },
  data() {
    return {
      appId : null,
      appName : '',
      step : 1,
      first:'',
      searchedKeyword: '',
      recomendItems: [],
      loadingRecomends:false,
      countryName: 'United States',
      countryCode: 'us',
      selectedAppIcon: '',
      selectedAppDev: '',
      valid: false,
      show: false,
      keywordInput: '',
      keywords: [],
      currency: 'USD',
      campaignBudget: null,
      keyRules: [
        (v) =>
          !this.keywords.find((kw) => kw.text === v) || 'Keyword already added',
      ],
      amountRules: [
        (v) => !!v || 'Bid amount is required',
        (v) =>
          (v && v != 0 && v > 0) ||
          'Bid amount must be numeric and more than 0',
        (v) =>
          (v < Number(this.campaignBudget) + 0.0001) ||
          'Bid amount must be less than or equal to  ' + Number(this.campaignBudget) ,
      ],
    };
  },
  props: {
    addKeysData: {
      type: Object,
      required: true,
      default() {
        return;
      },
    },
  },
  watch: {
    addKeysData: function (data) {
      this.show = true;
      this.appId = data.appId;
      this.currency = data.currency;
      this.appName = data.appName;
      this.countryName = this.getCountryName(data.countryCode);
      this.countryCode = data.countryCode;
      this.campaignBudget = data.campaignBudget;
      this.getApp();
    },
  },
  methods: {
    close() {
      this.show = false;
      this.keywordInput = '';
      this.keywords = [];
    },

    getCountryName(countryCode){
      const searchKey = { Code: countryCode };
      const countryName = COUNTRIES[COUNTRIES.map(function (item) { return item.Code; }).indexOf(searchKey.Code)];
      //console.log(countryName)
      return countryName.Name;
    },

    getAppDetails(){
      //console.log('getAppDetails');
    },

    addKeyword(): void {
      const keys = this.keywordInput.split(',');
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        //console.log(element);
        if (
          element &&
          !this.keywords.find((kw) => kw.text === element)
        ) {
          this.keywords.push({
            text: element,
            matchType: 'EXACT',
            bidAmount: { currency: this.currency, amount: '0.1' },
          });
        }
        this.keywordInput = '';
      }
    },

    deleteKeyword(keyword: string): void {
      this.keywords = this.keywords.filter((kw) => kw.text !== keyword);
    },

    save() {
      this.$emit('addKeysSaveClicked', this.keywords);
      this.close();
    },

    change() {
      this.$emit('addKeysCancelClicked');
      this.close();
    },

    getApp(){
      //console.log('getApp',this.appId, this.countryCode);
      this.loadingRecomends = true;
      this.$apollo
      .query({
        query: KEYWORD_RECOMENDS,
        variables: {
          appid: this.appId,
          country: this.countryCode.toLowerCase(),
          term: ""
        }
      })
      .then(res => {
        // console.log(res.data);
        this.loadingRecomends = false;
        this.recomendItems = res.data.KeywordRecomends.content?res.data.KeywordRecomends.content:[];
      })
      .catch(error => {
        //console.log(error);
        this.loadingRecomends = false;
      });

      if(LSS.ownApps && LSS.ownApps.length > 0) {
        for (let index = 0; index < LSS.ownApps.length; index++) {
          const element = LSS.ownApps[index];
          if(element.adamId == this.appId){
            this.selectedAppIcon = element.appIcon
            this.selectedAppDev = ''
          }
        }
      }
    },

    addKeywordPlusClicked(item){
      //console.log('addKeywordPlusClicked',item);
      if (
        item.keyword &&
        !this.keywords.find((kw) => kw.text === item.keyword)
      ) {
        this.keywords.push({
          text: item.keyword,
          matchType: 'EXACT',
          bidAmount: { currency: this.currency, amount: '0.1' },
        });
      }
    },
  },

  mounted(){
    // console.log('mounted')
  }
});
