var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "900px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-5",
            },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("addCreativeSet.addCreative")))]),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [
              _vm.languageDisplayNames.length == 0
                ? _c("v-container", [
                    _c("div", { staticClass: "my-2 red--text" }, [
                      _vm._v(
                        " App does not meet the minimum number of required assets to create & run creative sets. "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.languageDisplayNames.length != 0
                ? _c(
                    "v-container",
                    [
                      _c(
                        "v-form",
                        {
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addCreativeSet.setup"))
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("addCreativeSet.paragaraph")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-label", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      _vm._s("addCreativeSet.createSetName") +
                                        " "
                                    ),
                                    _c("sup", { staticClass: "red--text" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Creative Set Name",
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.csName,
                                      callback: function ($$v) {
                                        _vm.csName = $$v
                                      },
                                      expression: "csName",
                                    },
                                  }),
                                  _c("v-label", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("addCreativeSet.localLang")
                                      ) + " "
                                    ),
                                    _c("sup", { staticClass: "red--text" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]),
                                  _c("v-select", {
                                    attrs: {
                                      placeholder: "select language",
                                      items: _vm.languageDisplayNames,
                                      "item-text": "languageDisplayName",
                                      "item-value": "key",
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onLanguageSelection()
                                      },
                                    },
                                    model: {
                                      value: _vm.selectedLanguage,
                                      callback: function ($$v) {
                                        _vm.selectedLanguage = $$v
                                      },
                                      expression: "selectedLanguage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-3" }),
                          _c(
                            "v-row",
                            { attrs: { justify: "space-between" } },
                            [
                              _c("v-col", { attrs: { cols: "12", sm: "8" } }, [
                                _c(
                                  "h4",
                                  [
                                    _c("label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "addCreativeSet.assetSelection"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-help"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "addCreativeSet.assetSelectionPararaph1"
                                      )
                                    ) + " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "addCreativeSet.assetSelectionPararaph2"
                                      )
                                    ) + " "
                                  ),
                                  _c(
                                    "strong",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "addCreativeSet.assetSelectionPararaph3"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "addCreativeSet.assetSelectionPararaph4"
                                      )
                                    ) + " "
                                  ),
                                  _c(
                                    "strong",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "addCreativeSet.assetSelectionPararaph5"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "12", sm: "4" },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-width": 200,
                                        "offset-x": "",
                                        "max-width": "500",
                                      },
                                      model: {
                                        value: _vm.menuDisplays,
                                        callback: function ($$v) {
                                          _vm.menuDisplays = $$v
                                        },
                                        expression: "menuDisplays",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addCreativeSet.title1"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("v-simple-table", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "tbody",
                                                                    _vm._l(
                                                                      _vm.phones,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "tr",
                                                                          {
                                                                            key:
                                                                              item.size,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.size
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3939566074
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addCreativeSet.title2"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("v-simple-table", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "tbody",
                                                                    _vm._l(
                                                                      _vm.ipads,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "tr",
                                                                          {
                                                                            key:
                                                                              item.size,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.size
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1798225146
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.appPreviewDeviceWithAssets
                            ? _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    { attrs: { accordion: "" } },
                                    _vm._l(
                                      _vm.appPreviewDeviceWithAssets,
                                      function (value, name) {
                                        return _c(
                                          "v-expansion-panel",
                                          { key: name },
                                          [
                                            _c(
                                              "v-expansion-panel-header",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between align-center",
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "actions",
                                                      fn: function () {
                                                        return [
                                                          value.ipadError ||
                                                          value.iphoneError
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "error",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-alert-circle "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        value.deviceDisplayName
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-expansion-panel-content",
                                              [
                                                _c(
                                                  "v-row",
                                                  _vm._l(
                                                    value.screenshots,
                                                    function (
                                                      screenshotItem,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "3",
                                                          },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            staticClass:
                                                              "rounded-lg",
                                                            attrs: {
                                                              src:
                                                                screenshotItem.assetURL,
                                                              "lazy-src":
                                                                screenshotItem.assetURL,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "placeholder",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height ma-0",
                                                                          attrs: {
                                                                            align:
                                                                              "center",
                                                                            justify:
                                                                              "center",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-progress-circular",
                                                                            {
                                                                              attrs: {
                                                                                indeterminate:
                                                                                  "",
                                                                                color:
                                                                                  "grey lighten-5",
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              label: "",
                                                              value:
                                                                screenshotItem.assetGenId,
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.validateAssets(
                                                                  name,
                                                                  value
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedImgs,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.selectedImgs = $$v
                                                              },
                                                              expression:
                                                                "selectedImgs",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                value.ipadError
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara1"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara2"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara3"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara4"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara5"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                value.iphoneError
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara6"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara7"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara8"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara9"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addCreativeSet.screenshotsPara10"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addCreativeSet.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "primary",
                    depressed: "",
                    disabled:
                      _vm.selectedImgs.length == 0 ||
                      !_vm.isAssestsSelectionValid,
                  },
                  on: { click: _vm.creativeSetObject },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addCreativeSet.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }